import logo from "../../assets/data/logo.png";
import mail from "../../assets/data/mailicon.png";
import phone from "../../assets/data/phone.png";
import upload from "../../assets/data/upload.png";
import { useState, useContext } from "react";
import download from "../../assets/data/download.png";
import del from "../../assets/data/delete.png";
import send from "../../assets/data/invoicewhite.png";
import happy from "../../assets/data/happy.png";
import { ToastContainer, toast } from "react-toastify";
import { getFileExtension } from "../../services/helperFunctions";
import { deleteClientFile, uploadClientFile } from "../../services/api/clients";
import ImagePreview from "../modals/ImagePreview";
import PdfPreview from "../modals/PdfPreview";
import DeleteModal from "../modals/DeleteModal";
import { imageExtensions } from "../../constants";
import { AuthContext } from "../../context/AuthProvider";
function BookApt() {
  const [showModal, setShowModal] = useState(false);
  const [pana, setPana] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [uploadingPana, setUploadingPana] = useState(false);
  const [panaIndex, setPanaIndex] = useState(null);
  const [panaId, setPanaId] = useState("");
  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewFileName, setPreviewFileName] = useState("");
  const [previewFileUrl, setPreviewFileUrl] = useState("");
  const { user } = useContext(AuthContext);
  const handlePanoramexUpload = (e) => {
    setUploadingPana(true);
    const files = e.target.files;
    const allowedExtensions = [
      "pdf",
      "jpg",
      "jpeg",
      "png",
      "PDF",
      "JPG",
      "JPEG",
      "PNG",
    ];

    // Create an array to store promises for each file upload
    const uploadPromises = [];
    const responses = [];
    let anyInvalidFile = false;
    // Iterate over each file and create a payload for each
    for (const file of files) {
      const fileExtension = getFileExtension(file.name);
      if (allowedExtensions.includes(fileExtension)) {
        const payLoad = {
          clientId: "test",
          file,
          type: "PANORAMEX",
          userId: user.id,
        };
        const uploadPromise = uploadClientFile(payLoad)
          .then((res) => {
            responses.push(res.data.data); // Capture the response for each file
          })
          .catch((err) => {
            responses.push(null);
          });

        uploadPromises.push(uploadPromise);
      } else {
        setUploadingPana(false);
        toast.error("Supported file formats : png,jpg,jpeg & pdf");
        anyInvalidFile = true;
      }
    }

    if (anyInvalidFile && uploadPromises.length > 1) {
      toast.success("Files with valid format are uploaded");
    } else if (anyInvalidFile && uploadPromises.length === 1) {
      toast.success("File with valid format is uploaded");
    } else if (!anyInvalidFile && uploadPromises.length > 1) {
      toast.success("All files uploaded Successfully");
    } else if (!anyInvalidFile && uploadPromises.length === 1) {
      toast.success("File Uploaded Successfully");
    }

    uploadPromises.length > 0 &&
      Promise.all(uploadPromises)
        .then(() => {
          // All files uploaded successfully
          setUploadingPana(false);
          setPana((prevFiles) => [...prevFiles, ...responses]);
        })
        .catch((err) => {
          // Handle any error that occurred during the parallel uploads
          setPana((prevFiles) => [...prevFiles, ...responses]);
          setUploadingPana(false);
          toast.error(
            err?.response?.data?.message ||
              err.message ||
              "Failed to upload one or more files"
          );
        });
  };

  const delPana = () => {
    deleteClientFile(panaId)
      .then((res) => {
        toast.success("Panoramex deleted successfully");
        setPana((prevPana) => {
          const updatedPana = [...prevPana];
          updatedPana.splice(panaIndex, 1); // Remove the file at the specified index
          return updatedPana;
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ?? "Failed to delete Panoramex"
        );
      });
  };
  const handleFilePreview = (fileData) => {
    const { name, url } = fileData;

    if (imageExtensions.includes(getFileExtension(name))) {
      setPreviewImage(true);
    } else if (getFileExtension(name) === "pdf") {
      setPreviewPdf(true);
    }

    setPreviewFileName(name);
    setPreviewFileUrl(url);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
      />
      <div className="container appointment-ctr">
        <div className="row justify-content-center align-items-center text-center">
          <div className="col-lg-12 col-12">
            <h2>Book An Appointment</h2>
          </div>
        </div>
        <div className="row justify-content-center align-items-center text-center">
          <div className="col-lg-12 col-12">
            <img src={logo} alt="andent-logo" className="apt-logo" />
          </div>
        </div>
        <div className="form">
          <div className="row justify-content-start" style={{ width: "100%" }}>
            <div className="col-md-6 col-12 text-start">
              <div>
                <h2 className="popup-heading-2 text-start">First Name</h2>
                <input className="book-input" placeholder="Enter First Name" />
              </div>
              <div style={{ marginTop: "8px" }}>
                <h2 className="popup-heading-2 text-start">Email</h2>
                <div className="">
                  <input className="book-input" placeholder="Enter email" />
                  <img
                    src={mail}
                    alt="mail-icon"
                    className="small-icon book-mail-icon"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 text-start book-apt-pos">
              <div>
                <h2 className="popup-heading-2 text-start">Last Name</h2>
                <input className="book-input" placeholder="Enter Last Name" />
              </div>
              <div style={{ marginTop: "8px" }}>
                <h2 className="popup-heading-2 text-start">Number</h2>
                <div className="">
                  <input className="book-input" placeholder="Enter number" />
                  <img
                    src={phone}
                    alt="mail-icon"
                    className="small-icon book-mail-icon"
                  />
                </div>
              </div>
            </div>

            <div
              className="row justify-content-start"
              style={{ width: "100%", marginTop: "8px" }}
            >
              <div className="col-12 col-lg-12 text-start">
                <h2 className="popup-heading-2 text-start">Message</h2>
              </div>
              <div className="col-lg-12 col-12 text-start">
                <textarea
                  type=""
                  className="popup-inputs-5"
                  placeholder="Enter Text"
                  style={{ resize: "none" }}
                ></textarea>
              </div>
            </div>

            <div
              className="row justify-content-start"
              style={{
                width: "100%",
                marginTop: `${pana.length === 0 ? `0px` : `12px`}`,
              }}
            >
              {pana?.map((file, index) => (
                <h2
                  key={index}
                  className="popup-heading-3 text-start d-flex align-items-center justify-content-start"
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleFilePreview(file)}
                  >
                    {file?.name}
                  </span>

                  <a
                    href={file?.url}
                    download={file?.url}
                    style={{ marginLeft: "10px" }}
                  >
                    <img
                      src={download}
                      alt="download-icon"
                      className="small-icon"
                    />
                  </a>
                  <span
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setPanaIndex(index);
                      setPanaId(file?.id);
                      setShowModal(true);
                    }}
                  >
                    <img src={del} alt="delete-icon" className="small-icon" />
                  </span>
                </h2>
              ))}
            </div>
            <div
              className="row justify-content-start justify-content-md-start upload-pano-mob"
              style={{ width: "100%", marginTop: "12px" }}
            >
              <div className="col-6 col-lg-6 text-start uploadpano">
                <label className="andent-button-long">
                  <h2 className="button-text">
                    {uploadingPana ? "Uploading ..." : "Upload Panoramex"}
                  </h2>
                  <span className="d-flex align-items-center">
                    <img
                      src={upload}
                      alt="upload-icon"
                      className="small-icon"
                    />
                  </span>
                  <input
                    onChange={handlePanoramexUpload}
                    multiple
                    type="file"
                    style={{ display: "none" }}
                  />
                  {/* Button triggers file input click */}
                  <button type="button" style={{ display: "none" }}></button>
                </label>
              </div>
            </div>

            <div
              className=" justify-content-center align-items-center text-center text-md-start"
              style={{ width: "100%", marginTop: "12px" }}
            >
              <div className="col-lg-12 col-12">
                <p className="policy">
                  By submitting this form you agree to our{" "}
                  <span>privacy policy</span> as well as to receive marketing
                  emails from Andent. You can unsubscribe from these
                  communications at any time.
                </p>
              </div>
            </div>

            <div
              className="row justify-content-start d-flex upload-pano-mob"
              style={{ width: "100%" }}
            >
              <div
                className="col-12 col-lg-12 text-start d-flex justify-content-center submitform"
                style={{ gap: "24px" }}
              >
                <button
                  className="andent-button-long"
                  onClick={() => {
                    setSubmitted(true);
                  }}
                >
                  <h2 className="button-text">
                    Submit Form
                    <span
                      style={{
                        marginLeft: "8px",
                        bottom: "2px",
                        position: "relative",
                      }}
                    >
                      <img
                        src={send}
                        alt="genlink icon"
                        className="small-icon"
                      />
                    </span>
                  </h2>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${submitted ? `form-submitted` : `d-none`}`}
        onClick={() => {
          setSubmitted(false);
        }}
      >
        <div className="row justify-content-center text-center">
          <div className="col-lg-12 col-12">
            <img
              src={happy}
              alt="success-icon"
              style={{ width: "32px", height: "32px" }}
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-lg-12 col-12">
            <h2 className="popup-heading-2">
              Your form has been submitted successfully. We will be contacting
              you soon!
              <br />
              Thank you!
            </h2>
          </div>
        </div>
      </div>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalDescription={"Are you sure you want to delete this file?"}
        onConfirm={delPana}
      />
      <ImagePreview
        {...{
          previewImage,
          setPreviewImage,
          previewFileName,
          previewFileUrl,
        }}
      />
      <PdfPreview
        {...{
          previewPdf,
          setPreviewPdf,
          previewFileName,
          previewFileUrl,
        }}
      />
    </>
  );
}

export default BookApt;
