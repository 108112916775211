export const countries = [
  "Select Country",
  "Italy",
  "Albania",
  "United Kingdom",
  "USA",
  "Canada",
  "UK",
  "Australia",
  "Germany",
];
export const statuses = [
  "Select Status",
  "Contacted",
  "Invoice Sent",
  "Booked",
  "Procedure Done",
  "Contact Again",
];

export const imageExtensions = ["jpg", "jpeg", "png"];

export const allowedExtensions = [
  "pdf",
  "jpg",
  "jpeg",
  "png",
  "PDF",
  "JPG",
  "JPEG",
  "PNG",
];

export const uploadFileTypes = {
  INVOICE: "Invoice",
  RECEIPT: "Receipt",
  CONSENT_FORM: "Consent Form",
  CBCT: "Cbct",
  PANORAMEX: "Panaromex",
};
