import upload from "../../assets/data/upload.png";
import download from "../../assets/data/download.png";
import del from "../../assets/data/delete.png";
import add from "../../assets/data/add.png";
import { useRef, useState } from "react";
import mail from "../../assets/data/mailicon.png";
import phone from "../../assets/data/phone.png";
import { useEffect, useContext } from "react";
import {
  allowedExtensions,
  countries,
  statuses,
  uploadFileTypes,
} from "../../constants";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthProvider";
import {
  updateClient,
  uploadClientFile,
  deleteClientFile,
  getClients,
} from "../../services/api/clients";
import { capitalizeFirstLetter } from "../../services/helperFunctions";
import useData from "../../hooks/useData";
import { isValidNumber } from "../../services/helperFunctions";
import DeleteModal from "../modals/DeleteModal";
import { getClientById } from "../../services/api/clients";
import ImagePreview from "../modals/ImagePreview";
import { imageExtensions } from "../../constants";
import { getFileExtension } from "../../services/helperFunctions";
import PdfPreview from "../modals/PdfPreview";
import { isObject, isPlainObject } from "lodash";
function EditClient(props) {
  const { editClientId, setPopUpIsOpen } = props;
  const [clientData, setClientData] = useState([]);
  const { clientStatuses } = useData();
  const [viewMore, setViewMore] = useState(false);
  const [pana, setPana] = useState([]);

  const [receiptFiles, setReceiptFiles] = useState([]);
  const [consentFormFiles, setConsentFormFiles] = useState([]);
  const [cbctFiles, setCbctFiles] = useState([]);
  const [fileIdToBeDeleted, setFileIdToBeDeleted] = useState("");

  const [invoiceFiles, setInvoiceFiles] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("Select Country");
  const [clStatus, setclStatus] = useState("New");
  const [isMobile, setIsMobile] = useState(false);
  const { user } = useContext(AuthContext);
  const [uploadingInvoice, setUploadingInvoice] = useState(false);
  const [uploadingReceipt, setUploadingReceipt] = useState(false);
  const [uploadingPana, setUploadingPana] = useState(false);

  const [uploadingConcentForm, setUploadingConcentForm] = useState(false);
  const [uploadingCbct, setUploadingCbct] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [notes, setNotes] = useState("");
  const [updatingClient, setUpdatingClient] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileTypeToBeDeleted, setFileTypeToBeDeleted] = useState("");

  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewFileName, setPreviewFileName] = useState("");
  const [previewFileUrl, setPreviewFileUrl] = useState("");

  useEffect(() => {
    setFirstName(clientData?.firstName);
    setLastName(clientData?.lastName);
    setEmail(clientData?.email);
    setNumber(clientData?.number);
    setNotes(clientData?.notes);
    setSelectedCountry(clientData?.country ?? "Select Country");
    setclStatus(clientData?.status ?? "New");
    const uploadedPana = [];
    const uploadedInvoices = [];
    const uploadedReceipts = [];
    const uploadedConsentForms = [];
    const uploadedCbct = [];
    clientData?.clientFiles &&
      clientData?.clientFiles?.length !== 0 &&
      clientData.clientFiles.forEach((fileObj) => {
        if (fileObj.type === "PANORAMEX") {
          uploadedPana.push(fileObj);
        } else if (fileObj.type === "INVOICE") {
          uploadedInvoices.push(fileObj);
        } else if (fileObj.type === "RECEIPT") {
          uploadedReceipts.push(fileObj);
        } else if (fileObj.type === "CONSENT_FORM") {
          uploadedConsentForms.push(fileObj);
        } else if (fileObj.type === "CBCT") {
          uploadedCbct.push(fileObj);
        }
      });
    setPana(uploadedPana);
    setInvoiceFiles(uploadedInvoices);
    setReceiptFiles(uploadedReceipts);
    setConsentFormFiles(uploadedConsentForms);
    setCbctFiles(uploadedCbct);
  }, [clientData]);

  useEffect(() => {
    getClientById(editClientId)
      .then((res) => {
        setClientData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Failed to load Client");
      });
  }, [editClientId]);

  const handleFilePreview = (fileData) => {
    const { name, url } = fileData;

    if (imageExtensions.includes(getFileExtension(name))) {
      setPreviewImage(true);
    } else if (getFileExtension(name) === "pdf") {
      setPreviewPdf(true);
    }

    setPreviewFileName(name);
    setPreviewFileUrl(url);
  };

  const handleMultiFileUpload = (e, type) => {
    if (type === "PANORAMEX") {
      setUploadingPana(true);
    } else if (type === "INVOICE") {
      setUploadingInvoice(true);
    } else if (type === "RECEIPT") {
      setUploadingReceipt(true);
    } else if (type === "CONSENT_FORM") {
      setUploadingConcentForm(true);
    } else if (type === "CBCT") {
      setUploadingCbct(true);
    }
    const files = e.target.files;

    const uploadPromises = [];
    const responses = [];
    let anyInvalidFile = false;
    for (const file of files) {
      const fileExtension = getFileExtension(file.name);
      if (allowedExtensions.includes(fileExtension)) {
        const payLoad = {
          clientId: editClientId,
          file,
          type,
          userId: user.id,
        };
        const uploadPromise = uploadClientFile(payLoad)
          .then((res) => {
            responses.push(res.data.data); // Capture the response for each file
          })
          .catch((err) => {
            responses.push(null);
          });

        uploadPromises.push(uploadPromise);
      } else {
        if (type === "PANORAMEX") {
          setUploadingPana(false);
        } else if (type === "INVOICE") {
          setUploadingInvoice(false);
        } else if (type === "RECEIPT") {
          setUploadingReceipt(false);
        } else if (type === "CONSENT_FORM") {
          setUploadingConcentForm(false);
        } else if (type === "CBCT") {
          setUploadingCbct(false);
        }
        toast.error("Supported file formats : png,jpg,jpeg & pdf");
        anyInvalidFile = true;
      }
    }

    if (anyInvalidFile && uploadPromises.length > 1) {
      toast.success("Files with valid format are uploaded");
    } else if (anyInvalidFile && uploadPromises.length === 1) {
      toast.success("File with valid format is uploaded");
    } else if (!anyInvalidFile && uploadPromises.length > 1) {
      toast.success("All files uploaded Successfully");
    } else if (!anyInvalidFile && uploadPromises.length === 1) {
      toast.success("File Uploaded Successfully");
    }

    uploadPromises.length > 0 &&
      Promise.all(uploadPromises)
        .then(() => {})
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Failed to upload one or more files"
          );
        })
        .finally(() => {
          if (type === "PANORAMEX") {
            setPana((prevFiles) => [...prevFiles, ...responses]);
            setUploadingPana(false);
          } else if (type === "INVOICE") {
            setInvoiceFiles((prevFiles) => [...prevFiles, ...responses]);
            setUploadingInvoice(false);
          } else if (type === "RECEIPT") {
            setReceiptFiles((prevFiles) => [...prevFiles, ...responses]);
            setUploadingReceipt(false);
          } else if (type === "CONSENT_FORM") {
            setConsentFormFiles((prevFiles) => [...prevFiles, ...responses]);
            setUploadingConcentForm(false);
          } else if (type === "CBCT") {
            setCbctFiles((prevFiles) => [...prevFiles, ...responses]);
            setUploadingCbct(false);
          }
        });
  };

  useEffect(() => {
    if (window.innerWidth < 760) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleStatusChange = (event) => {
    setclStatus(event.target.value);
  };

  const handleFileDelete = () => {
    setShowModal(false);
    deleteClientFile(fileIdToBeDeleted)
      .then((res) => {
        toast.success(
          `${uploadFileTypes[fileTypeToBeDeleted]} deleted successfully`
        );
        if (fileTypeToBeDeleted === "INVOICE") {
          setInvoiceFiles((prevFiles) =>
            prevFiles.filter((file) => file.id !== fileIdToBeDeleted)
          );
        } else if (fileTypeToBeDeleted === "RECEIPT") {
          setReceiptFiles((prevFiles) =>
            prevFiles.filter((file) => file.id !== fileIdToBeDeleted)
          );
        } else if (fileTypeToBeDeleted === "CONSENT_FORM") {
          setConsentFormFiles((prevFiles) =>
            prevFiles.filter((file) => file.id !== fileIdToBeDeleted)
          );
        } else if (fileTypeToBeDeleted === "CBCT") {
          setCbctFiles((prevFiles) =>
            prevFiles.filter((file) => file.id !== fileIdToBeDeleted)
          );
        } else if (fileTypeToBeDeleted === "PANORAMEX") {
          setPana((prevFiles) =>
            prevFiles.filter((file) => file.id !== fileIdToBeDeleted)
          );
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
            `Failed to delete ${uploadFileTypes[fileTypeToBeDeleted]}`
        );
      });
  };

  function handleClientUpdate() {
    if (
      !firstName ||
      !lastName
      //only first and last name are mandatory to keep a record
      //!email ||
      //!number ||
      //!notes ||
      //!selectedCountry ||
      //!clStatus
    ) {
      toast.error("Fill First and Last Name");
    } else if (number && !isValidNumber(number)) {
      toast.error("Number is not valid");
    } else if (number && number.length < 6) {
      toast.error("Number can not have less than 6 characters");
    } else {
      const payLoad = {
        id: editClientId,
        firstName,
        lastName,
        email,
        number,
        notes,
        country: selectedCountry,
        status: clStatus,
        source: clientData?.source ?? "Manual Entry",
      };
      setUpdatingClient(true);
      updateClient(payLoad)
        .then((res) => {
          setUpdatingClient(false);
          toast.success("Client updated Successfully");
          setPopUpIsOpen(false);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message ?? "Failed to update client"
          );
          setUpdatingClient(false);
        });
    }
  }

  return (
    <>
      <div className="row justify-content-center text-center">
        <div className="col-lg-12 col-12">
          <h2 className="popup-heading">
            {capitalizeFirstLetter(clientData?.firstName)}{" "}
            {capitalizeFirstLetter(clientData?.lastName)}
          </h2>
        </div>
      </div>
      <h1 className="closeBtn" onClick={() => setPopUpIsOpen(false)}>
        &times;
      </h1>
      <div className="row justify-content-start" style={{ width: "100%" }}>
        <div className="col-12 col-md-6 text-start">
          <div>
            <h2 className="popup-heading-2 text-start">First Name</h2>
            <input
              tabindex="1"
              className="popup-inputs-small"
              placeholder="Enter First Name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </div>
          {isMobile ? (
            <div style={{ marginTop: "8px" }}>
              <h2 className="popup-heading-2 text-start">Last Name</h2>
              <input
                tabindex="2"
                className="popup-inputs-small"
                placeholder="Enter Last Name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </div>
          ) : (
            <div style={{ marginTop: "8px" }}>
              <h2 className="popup-heading-2 text-start">Email</h2>
              <input
                tabindex="3"
                className="popup-inputs-small"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{ paddingRight: "30px" }}
              />
              <img
                src={mail}
                alt="mail-icon"
                className="small-icon client-mail-icon"
              />
            </div>
          )}
          {isMobile ? (
            <div style={{ marginTop: "8px" }}>
              <h2 className="popup-heading-2 text-start">Email</h2>
              <input
                tabindex="3"
                className="popup-inputs-small"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{ paddingRight: "30px" }}
              />
              <img
                src={mail}
                alt="mail-icon"
                className="small-icon client-mail-icon"
              />
            </div>
          ) : (
            <div style={{ marginTop: "8px" }}>
              <h2 className="popup-heading-2 text-start">Country</h2>
              <select
                tabindex="5"
                value={selectedCountry}
                onChange={handleCountryChange}
                className="popup-inputs-small-dropdown"
              >
                {countries.map((country, index) => (
                  <option key={index}>{country}</option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div className="col-md-6 col-12 text-start mob-top-pad">
          {isMobile ? (
            <div>
              <h2 className="popup-heading-2 text-start">Number</h2>
              <input
                tabindex="4"
                className="popup-inputs-small"
                placeholder="Enter number"
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                style={{ paddingRight: "30px" }}
              />
              <img
                src={phone}
                alt="mail-icon"
                className="small-icon client-mail-icon"
              />
            </div>
          ) : (
            <div>
              <h2 className="popup-heading-2 text-start">Last Name</h2>
              <input
                tabindex="2"
                className="popup-inputs-small"
                placeholder="Enter Last Name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </div>
          )}
          {isMobile ? (
            <div style={{ marginTop: "8px" }}>
              <h2 className="popup-heading-2 text-start">Country</h2>
              <select
                tabindex="5"
                value={selectedCountry}
                onChange={handleCountryChange}
                className="popup-inputs-small-dropdown"
              >
                {countries.map((country, index) => (
                  <option key={index} value={country} disabled={index === 0}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div style={{ marginTop: "8px" }}>
              <h2 className="popup-heading-2 text-start">Number</h2>
              <input
                tabindex="4"
                className="popup-inputs-small"
                placeholder="Enter number"
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                style={{ paddingRight: "30px" }}
              />
              <img
                src={phone}
                alt="mail-icon"
                className="small-icon client-mail-icon"
              />
            </div>
          )}
        </div>

        <div
          className="row justify-content-start new-client-updated-pad2"
          style={{ width: "100%" }}
        >
          <div className="col-12 col-lg-12 text-start">
            <h2
              className="popup-heading-2 text-start"
              style={{ fontSize: "14px" }}
            >
              Upload Format (Jpg, Png, Pdf)
            </h2>
          </div>
        </div>
        <div
          className="row justify-content-start new-client-updated-pad2"
          style={{ width: "100%" }}
        >
          <div className="col-12 col-lg-10 text-start d-flex justify-content-center justify-content-md-start">
            <label
              className={`andent-button-3`}
              style={{ width: isMobile ? "auto" : "150px" }}
            >
              <h2 className="button-text">
                {uploadingPana ? "Uploading ..." : "Panoramex"}
              </h2>
              <span className="d-flex align-items-center">
                <img src={upload} alt="upload-icon" className="small-icon" />
              </span>
              <input
                id="my-file"
                onChange={(e) => handleMultiFileUpload(e, "PANORAMEX")}
                multiple
                type="file"
                style={{ display: "none" }}
              />
              {/* Button triggers file input click */}
              <button
                disabled={uploadingPana}
                type="button"
                style={{ display: "none" }}
              ></button>
            </label>
            {pana.length > 1 ? (
              <div className="col-6 col-lg-6 text-start d-flex justify-content-end align-items-center">
                <u
                  onClick={() => {
                    setViewMore(!viewMore);
                  }}
                >
                  <h2 className="popup-heading-2">
                    {viewMore ? "View Less Uploads" : "View More Uploads"}
                  </h2>
                </u>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className="row justify-content-start mt-2"
          style={{ width: "100%" }}
        >
          {pana.length > 0 && !viewMore ? (
            <h2 className="popup-heading-3 text-start d-flex align-items-center justify-content-start">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleFilePreview(pana[0])}
              >
                {pana[0].name}
              </span>
              <a
                href={pana[0].url}
                download={pana[0].url}
                style={{ marginLeft: "10px" }}
              >
                <img
                  src={download}
                  alt="download-icon"
                  className="small-icon"
                />
              </a>
              <span
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setFileTypeToBeDeleted("PANORAMEX");
                  setFileIdToBeDeleted(pana[0].id);
                  setShowModal(true);
                }}
              >
                <img src={del} alt="delete-icon" className="small-icon" />
              </span>
            </h2>
          ) : pana.length > 1 && viewMore ? (
            pana.map((file, index) => (
              <h2
                key={index}
                className="popup-heading-3 text-start d-flex align-items-center justify-content-start"
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleFilePreview(file)}
                >
                  {file?.name}
                </span>

                <a
                  href={file?.url}
                  download={file?.url}
                  style={{ marginLeft: "10px" }}
                >
                  <img
                    src={download}
                    alt="download-icon"
                    className="small-icon"
                  />
                </a>
                <span
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setFileTypeToBeDeleted("PANORAMEX");
                    setFileIdToBeDeleted(file?.id);
                    setShowModal(true);
                  }}
                >
                  <img src={del} alt="delete-icon" className="small-icon" />
                </span>
              </h2>
            ))
          ) : (
            ""
          )}
        </div>

        <div className="row justify-content-start text-center new-client-updated-pad">
          <div className="col-lg-12 col-12 d-flex justify-content-start">
            <label className="andent-button-3">
              <h2 className="button-text">
                {isMobile
                  ? uploadingInvoice
                    ? "Uploading ..."
                    : "Invoice"
                  : uploadingInvoice
                  ? "Uploading ..."
                  : "Upload Invoice"}
              </h2>
              <span className="d-flex align-items-center">
                <img src={upload} alt="upload-icon" className="small-icon" />
              </span>
              <input
                id="my-file1"
                multiple
                onChange={(e) => handleMultiFileUpload(e, "INVOICE")}
                type="file"
                style={{ display: "none" }}
              />
              {/* Button triggers file input click */}
              <button
                disabled={uploadingInvoice}
                type="button"
                style={{ display: "none" }}
              ></button>
            </label>
          </div>
          <div className="col-lg-12 col-12 d-flex flex-column justify-content-start new-client-updated-pad2">
            {invoiceFiles?.map((file, index) => (
              <h2
                key={index}
                className="popup-heading-3 text-start d-flex align-items-center justify-content-start"
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleFilePreview(file)}
                >
                  {file?.name}
                </span>

                <a
                  href={file?.url}
                  download={file?.url}
                  style={{ marginLeft: "10px" }}
                >
                  <img
                    src={download}
                    alt="download-icon"
                    className="small-icon"
                  />
                </a>
                <span
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setFileTypeToBeDeleted("INVOICE");
                    setFileIdToBeDeleted(file?.id);
                    setShowModal(true);
                  }}
                >
                  <img src={del} alt="delete-icon" className="small-icon" />
                </span>
              </h2>
            ))}
          </div>

          <div className="col-lg-12 col-12 d-flex justify-content-start new-client-updated-pad">
            <label className="andent-button-3">
              <h2 className="button-text">
                {isMobile
                  ? uploadingReceipt
                    ? "Uploading"
                    : "Receipt"
                  : uploadingReceipt
                  ? "Uploading ..."
                  : "Upload Receipt"}
              </h2>
              <span className="d-flex align-items-center">
                <img src={upload} alt="upload-icon" className="small-icon" />
              </span>
              <input
                id="my-file2"
                multiple
                onChange={(e) => handleMultiFileUpload(e, "RECEIPT")}
                type="file"
                style={{ display: "none" }}
              />
              {/* Button triggers file input click */}
              <button
                disabled={uploadingReceipt}
                type="button"
                style={{ display: "none" }}
              ></button>
            </label>
          </div>
          <div className="col-lg-12 col-12 d-flex flex-column justify-content-start new-client-updated-pad2">
            {receiptFiles?.map((file, index) => (
              <h2
                key={index}
                className="popup-heading-3 text-start d-flex align-items-center justify-content-start"
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleFilePreview(file)}
                >
                  {file?.name}
                </span>

                <a
                  href={file?.url}
                  download={file?.url}
                  style={{ marginLeft: "10px" }}
                >
                  <img
                    src={download}
                    alt="download-icon"
                    className="small-icon"
                  />
                </a>
                <span
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setFileTypeToBeDeleted("RECEIPT");
                    setFileIdToBeDeleted(file?.id);
                    setShowModal(true);
                  }}
                >
                  <img src={del} alt="delete-icon" className="small-icon" />
                </span>
              </h2>
            ))}
          </div>
          <div className="col-lg-12 col-12 d-flex justify-content-start new-client-updated-pad">
            <label className="andent-button-3">
              <h2 className="button-text">
                {uploadingConcentForm ? "Uploading ..." : "Consent Form"}
              </h2>
              <span className="d-flex align-items-center">
                <img src={upload} alt="upload-icon" className="small-icon" />
              </span>
              <input
                id="my-file3"
                multiple
                onChange={(e) => handleMultiFileUpload(e, "CONSENT_FORM")}
                type="file"
                style={{ display: "none" }}
              />
              {/* Button triggers file input click */}
              <button
                disabled={uploadingConcentForm}
                type="button"
                style={{ display: "none" }}
              ></button>
            </label>
          </div>
          <div className="col-lg-12 col-12 d-flex flex-column justify-content-start new-client-updated-pad2 ">
            {consentFormFiles?.map((file, index) => (
              <h2
                key={index}
                className="popup-heading-3 text-start d-flex align-items-center justify-content-start"
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleFilePreview(file)}
                >
                  {file?.name}
                </span>

                <a
                  href={file?.url}
                  download={file?.url}
                  style={{ marginLeft: "10px" }}
                >
                  <img
                    src={download}
                    alt="download-icon"
                    className="small-icon"
                  />
                </a>
                <span
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setFileTypeToBeDeleted("CONSENT_FORM");
                    setFileIdToBeDeleted(file?.id);
                    setShowModal(true);
                  }}
                >
                  <img src={del} alt="delete-icon" className="small-icon" />
                </span>
              </h2>
            ))}
          </div>
          <div className="col-lg-12 col-12 d-flex justify-content-start new-client-updated-pad">
            <label className="andent-button-3">
              <h2 className="button-text">
                {isMobile
                  ? uploadingCbct
                    ? "Uploading"
                    : "CBCT"
                  : uploadingCbct
                  ? "Uploading ..."
                  : "Upload CBCT"}
              </h2>
              <span className="d-flex align-items-center">
                <img src={upload} alt="upload-icon" className="small-icon" />
              </span>
              <input
                id="my-file4"
                multiple
                onChange={(e) => handleMultiFileUpload(e, "CBCT")}
                type="file"
                style={{ display: "none" }}
              />
              {/* Button triggers file input click */}
              <button
                disabled={uploadingCbct}
                type="button"
                style={{ display: "none" }}
              ></button>
            </label>
          </div>
          <div className="col-lg-12 col-12 d-flex flex-column justify-content-start new-client-updated-pad2">
            {cbctFiles?.map((file, index) => (
              <h2
                key={index}
                className="popup-heading-3 text-start d-flex align-items-center justify-content-start"
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleFilePreview(file)}
                >
                  {file?.name}
                </span>

                <a
                  href={file?.url}
                  download={file?.url}
                  style={{ marginLeft: "10px" }}
                >
                  <img
                    src={download}
                    alt="download-icon"
                    className="small-icon"
                  />
                </a>
                <span
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setFileTypeToBeDeleted("CBCT");
                    setFileIdToBeDeleted(file?.id);
                    setShowModal(true);
                  }}
                >
                  <img src={del} alt="delete-icon" className="small-icon" />
                </span>
              </h2>
            ))}
          </div>
        </div>
      </div>
      <div className="row justify-content-start" style={{ width: "100%" }}>
        <div className="col-12 col-lg-12">
          <h2 className="popup-heading-2 text-start">Client Status</h2>
        </div>
        <div className="col-12 col-lg-6">
          <select
            tabindex="6"
            value={clStatus}
            onChange={handleStatusChange}
            className="popup-inputs-small-dropdown"
          >
            {clientStatuses?.map((stat, index) => (
              <option key={index} value={stat} disabled={index === 0}>
                {stat}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row justify-content-start" style={{ width: "100%" }}>
        <div className="col-12 col-lg-12 text-start">
          <h2 className="popup-heading-2 text-start">Client Notes</h2>
        </div>
        <div className="col-lg-12 col-12 text-start">
          <textarea
            tabindex="7"
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
            type=""
            className="popup-inputs-4"
            placeholder="Enter Text"
            style={{ resize: "none" }}
          ></textarea>
        </div>
      </div>

      <div
        className="row justify-content-start d-flex"
        style={{ width: "100%" }}
      >
        <div
          className="col-12 col-lg-12 text-start d-flex justify-content-center"
          style={{ gap: "24px" }}
        >
          <button
            tabindex="8"
            disabled={updatingClient}
            className="andent-button-3"
            onClick={handleClientUpdate}
          >
            <h2 className="button-text">
              {updatingClient ? "Updating ..." : "Update Client"}
              <span
                style={{
                  marginLeft: "8px",
                  bottom: "2px",
                  position: "relative",
                }}
              >
                <img src={add} alt="genlink icon" className="small-icon" />
              </span>
            </h2>
          </button>
        </div>
        <DeleteModal
          showModal={showModal}
          setShowModal={setShowModal}
          modalDescription={"Are you sure you want to delete this file?"}
          onConfirm={handleFileDelete}
        />
        <ImagePreview
          {...{
            previewImage,
            setPreviewImage,
            previewFileName,
            previewFileUrl,
          }}
        />
        <PdfPreview
          {...{
            previewPdf,
            setPreviewPdf,
            previewFileName,
            previewFileUrl,
          }}
        />
      </div>
    </>
  );
}

export default EditClient;
