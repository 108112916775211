import Router from './Routing/Router';
import './index.css';

function App() {
  return (
    <>
      <Router/>
    </>
  );
}

export default App;
